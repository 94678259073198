var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb", staticStyle: { height: "45px" } },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            staticStyle: { width: "88px", height: "36px" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("TitleModule", { attrs: { title: "基本信息" } }),
        _c("info-table", {
          attrs: {
            num: 3,
            tableData: _vm.basicInfo,
            dataValue: _vm.formInline,
          },
        }),
        _c("TitleModule", {
          staticStyle: { "margin-top": "30px" },
          attrs: { title: "设备列表" },
        }),
        _c(
          "div",
          { staticClass: "tableWrapper" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "100%", "font-size": "12px" },
                attrs: {
                  "header-cell-class-name": "header-call-style",
                  data: _vm.tableData,
                },
              },
              [
                _vm._l(_vm.tableCols, function (item) {
                  return _c("el-table-column", {
                    key: item.prop,
                    attrs: {
                      prop: item.prop,
                      label: item.label,
                      width: item.width,
                      align: "center",
                      formatter: item.formatter,
                    },
                  })
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_status"),
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.equipmentState == 1
                            ? _c(
                                "span",
                                [
                                  scope.row.actualState == 2
                                    ? _c(
                                        "font",
                                        {
                                          class: {
                                            red: scope.row.actualState == 2,
                                          },
                                        },
                                        [_vm._v("离线")]
                                      )
                                    : _vm._e(),
                                  scope.row.actualState == 1
                                    ? _c("font", [_vm._v("在线")])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          scope.row.equipmentState == 2
                            ? _c("span", [_c("font", [_vm._v("无")])], 1)
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: _vm.$t("searchModule.Update_time"),
                    width: "",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.actualStateUpdated
                                  ? scope.row.actualStateUpdated
                                  : ""
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.$route.meta.authority.button.view
                  ? _c("el-table-column", {
                      attrs: { label: "操作", width: "140", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-dropdown",
                                  {
                                    on: {
                                      command: function ($event) {
                                        return _vm.handleCommand(
                                          $event,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                      },
                                      [_vm._v(" 操作 ")]
                                    ),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _vm.$route.meta.authority.button.view
                                          ? _c(
                                              "el-dropdown-item",
                                              { attrs: { command: "a" } },
                                              [_vm._v("查看")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2371204538
                      ),
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "pagerWrapper" }, [
          _c(
            "div",
            { staticClass: "block" },
            [
              _vm.total != 0
                ? _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.page,
                      "page-size": _vm.pageSize,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.handleCurrentChange },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }